/* FrequentlyAskedQuestions.css */

/* Root Variables for Consistent Styling */
:root {
  --primary-color: #00d8a7;
  --background-color: #f9f9f9;
  --text-color: #333;
  --heading-color: #071D44;
  --faq-background: #ffffff;
  --border-radius: 10px;
  --transition-speed: 0.3s;
}

.faq-section {
  padding: 60px 20px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.faq-header {
  max-width: 800px;
  margin-bottom: 40px;
}

.faq-header h2 {
  color: var(--heading-color);
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.faq-header p {
  color: #666;
  font-size: 1.1rem;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.faq-item {
  background-color: var(--faq-background);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
}

.faq-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.faq-question {
  width: 100%;
  background: none;
  border: none;
  padding: 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.1rem;
  color: var(--text-color);
  transition: background-color var(--transition-speed) ease;
}

.faq-question:hover,
.faq-question:focus {
  background-color: #f0f0f0;
  outline: none;
}

.faq-question .icon {
  font-size: 1.2rem;
  color: var(--primary-color);
  transition: transform var(--transition-speed) ease;
}

.faq-item.active .faq-question .icon {
  transform: rotate(180deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  padding: 0 20px;
  background-color: #fafafa;
  transition: max-height var(--transition-speed) ease, padding var(--transition-speed) ease;
}

.faq-answer.show {
  max-height: 200px; /* Adjust based on content */
  padding: 20px;
}

.faq-answer p {
  margin: 0;
  color: #555;
  line-height: 1.6;
  font-size: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .faq-header h2 {
    font-size: 2rem;
  }

  .faq-question {
    font-size: 1rem;
  }

  .faq-answer p {
    font-size: 0.95rem;
  }
}
