/* Base Styles */
.statistics-container {
  position: relative;
  padding: 40px 20px;
  background-color: #071D44;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  z-index: 10;
  overflow: hidden;
}

.statistics-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/public/images/layer1.png') no-repeat center center/cover;
  opacity: 0.6;
  z-index: -1;
}

.statistics-main-heading {
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 700;
}

.statistics-sub-heading {
  color: #00d8a7;
  font-size: 1.75rem;
  margin-bottom: 40px;
  font-weight: 600;
}

.statistics-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; /* Allow items to wrap for smaller screens */
}

.stat-item {
  flex: 1;
  max-width: 300px; /* Ensure a max width for each stat item */
  color: white;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-10px); /* Add a hover effect for a modern feel */
}

.stat-icon img {
  height: 60px;
  margin-bottom: 15px;
}

.stat-number {
  font-size: 2rem;
  font-weight: bold;
  color: #00d8a7;
}

.stat-description {
  font-size: 1.1rem;
  margin-top: 10px;
}

/* Responsive Styles */

/* For large desktops */
@media (min-width: 1200px) {
  .statistics-main-heading {
    font-size: 3rem;
  }

  .statistics-sub-heading {
    font-size: 2rem;
  }

  .stat-number {
    font-size: 2.5rem;
  }

  .stat-description {
    font-size: 1.25rem;
  }
}

/* For tablets */
@media (max-width: 1024px) {
  .statistics-main-heading {
    font-size: 2.2rem;
  }

  .statistics-sub-heading {
    font-size: 1.6rem;
  }

  .statistics-content {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .stat-item {
    margin: 15px;
  }

  .stat-number {
    font-size: 1.8rem;
  }

  .stat-description {
    font-size: 1rem;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .statistics-container {
    padding: 30px 15px;
  }

  .statistics-main-heading {
    font-size: 2rem;
  }

  .statistics-sub-heading {
    font-size: 1.4rem;
  }

  .statistics-content {
    flex-direction: column;
  }

  .stat-item {
    margin: 10px 0;
  }

  .stat-number {
    font-size: 1.6rem;
  }

  .stat-description {
    font-size: 0.95rem;
  }
}

/* For small mobile devices */
@media (max-width: 480px) {
  .statistics-main-heading {
    font-size: 1.8rem;
  }

  .statistics-sub-heading {
    font-size: 1.2rem;
  }

  .stat-number {
    font-size: 1.4rem;
  }

  .stat-description {
    font-size: 0.9rem;
  }
}
