@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

/* Set the entire page background to match the hero section */
body, html {
  background-color: #0c1d3c; /* Ensures the full page has the same background */
  margin: 0;
  padding: 0;
}

.linkfast-page-container {
  background-color: #0c1d3c; /* Match the hero section background */
  padding-bottom: 50px;
  font-family: 'DM Serif Display', serif;
}

.linkfast-hero-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0 50px;
  background-color: #0c1d3c;
  color: white;
  overflow: hidden;
  perspective: 1px;
}

.linkfast-hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  background: url('/public/images/hero-background.jpg') no-repeat center center/cover;
  opacity: 0.4;
  transform: translateZ(-1px) scale(2);
  z-index: 0;
}

.linkfast-hero-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300%;
  background: url('/public/images/hero-background.jpg') no-repeat center center/cover;
  opacity: 0.2;
  transform: translateZ(-0.5px) scale(1.5);
  z-index: -1;
}

.hero-text {
  position: relative;
  flex: 1;
  text-align: left;
  z-index: 1;
  margin-top: 60px;
  margin-left: 60px;
}

.hero-text h1 {
  font-size: 2.75rem;
  margin: 0;
  color: #00D8A7;
}

.hero-text h2 {
  font-size: 2.75rem;
  margin: 10px 0 20px 0;
}

.hero-text p {
  font-size: 1.02rem;
  margin: 10px 0;
  line-height: 1.6;
}

.learn-more-button {
  padding: 12px 24px;
  background: linear-gradient(45deg, #00d8a7, #005bea);
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-top: 20px;
  font-family: "Titillium Web", sans-serif;
}

.learn-more-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.learn-more-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hero-image {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  animation: slideIn 1s ease-out;
}

.hero-image img {
  max-width: 80%;
  height: auto;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Styling for the video container */
.video-container {
  margin-top: 50px;
  padding: 0;
  background-color: #061D44;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  margin: 50px auto; /* Center the container */
}

.video-container iframe {
  border: none;
  border-radius: 0;
  width: 100%;
  height: 400px; /* Height set to 400px */
  display: block;
  margin: 0;
  padding: 0;
}
