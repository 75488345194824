/* File: src/solutions-pages/NetworkInfrastructure.css */

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.network-infrastructure {
  font-family: 'Titillium Web', sans-serif;
  color: #333;
}

/* Utility Classes */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Section Styles */
section {
  padding: 80px 0;
}

section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #08224a;
  text-align: center;
}

section p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
}

/* Hero Section */
.hero-section {
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(8, 34, 74, 0.8) 0%, rgba(8, 34, 74, 0.6) 100%);
  z-index: 1;
}

.hero-overlay {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
}

.hero-section h1 {
  font-size: 4rem;
  margin-bottom: 20px;
  color: #fff;
  line-height: 1.2;
}

.hero-section p {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 40px;
  color: #fff;
}

.hero-button {
  padding: 15px 30px;
  background: linear-gradient(45deg, #00d8a7, #005bea);
  border: none;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
  transition: transform 0.3s, box-shadow 0.3s;
  font-family: 'Titillium Web', sans-serif;
  position: relative;
  z-index: 2;
}

.hero-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.scroll-down-indicator {
  position: absolute;
  bottom: 20px;
  width: 30px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  pointer-events: none; /* Ensure it doesn't block interactions */
  z-index: 1; /* Lower than parallax-overlay */
}

.scroll-down-indicator::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 50%;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  transform: translateX(-50%);
  animation: scroll 2s infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
    transform: translate(-50%, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
}

/* Overview Section */
.overview-section {
  background-color: #f9f9f9;
}

.overview-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.overview-text {
  flex: 1;
  min-width: 280px;
  margin-right: 40px;
}

.overview-text p {
  margin-bottom: 20px;
}

.overview-image {
  flex: 1;
  min-width: 280px;
}

.overview-image img {
  width: 100%;
  border-radius: 10px;
}

/* Services Section */
.network-services-section {
  background-color: #fff;
}

.network-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  justify-items: center;
  grid-auto-flow: dense;
}

.network-service-card {
  background: linear-gradient(135deg, #e0f7fa, #e0f2f1);
  width: 100%;
  max-width: 350px;
  padding: 40px 30px;
  text-align: center;
  border-radius: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
}

.network-service-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(0, 0, 0, 0.05);
  transform: rotate(45deg);
}

.network-service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.network-service-icon {
  font-size: 3rem;
  color: #00d8a7;
  margin-bottom: 20px;
}

.network-service-card h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #08224a;
}

.network-service-card p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Read More Link */
.network-read-more {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  color: #005bea;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.network-read-more:hover {
  color: #00d8a7;
}

/* Brands Section */
.brands-section {
  background-color: #f9f9f9;
}

.brands-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.brand-item {
  width: 150px;
  transition: transform 0.3s ease;
}

.brand-item img {
  width: 100%;
  height: auto;
  object-fit: contain;
  filter: grayscale(100%);
  opacity: 0.8;
  transition: filter 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
}

.brand-item:hover img {
  filter: grayscale(0%);
  opacity: 1;
  transform: scale(1.05);
}

/* Parallax Section */
.parallax-section {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  text-align: center;
  padding: 200px 0; /* Increased padding to make the section taller */
  min-height: 600px;
}

.parallax-overlay {
  position: relative; /* Added */
  z-index: 2; /* Added */
  background-color: rgba(0, 37, 77, 0.7);
  padding: 40px 20px;
  border-radius: 10px;
  display: inline-block;
  max-width: 800px;
  margin: 0 auto;
}

.parallax-section h2 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  color: #fff;
}

.parallax-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.8;
  color: #fff;
}

.parallax-button {
  padding: 15px 30px;
  background: #00d8a7;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
  z-index: 3; /* Ensure it's above the overlay */
}

.parallax-button:hover {
  background-color: #005bea;
  transform: translateY(-5px);
}

/* Call to Action Section */
.cta-section {
  background: linear-gradient(45deg, #00d8a7, #005bea);
  text-align: center;
  color: #fff;
}

.cta-section h2 {
  font-size: 2.8rem;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  line-height: 1.8;
}

.cta-button {
  padding: 15px 30px;
  background-color: #08224a;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Titillium Web', sans-serif;
}

.cta-button:hover {
  background-color: #005e8c;
  transform: translateY(-5px);
}

/* Responsive Styles */
@media (max-width: 992px) {
  .hero-section h1 {
    font-size: 3rem;
  }

  .hero-section p {
    font-size: 1.1rem;
  }

  .overview-content {
    flex-direction: column;
  }

  .overview-text {
    margin-right: 0;
    margin-bottom: 30px;
  }

  section h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2.5rem;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .hero-overlay {
    padding: 40px 20px;
  }

  .parallax-section {
    background-attachment: scroll; /* Change fixed to scroll on smaller screens */
  }

  .brands-grid {
    gap: 20px;
  }

  .brand-item {
    width: 100px;
  }
}

@media (max-width: 480px) {
  .network-service-card {
    padding: 30px 20px;
  }

  .parallax-section {
    padding: 150px 0;
  }

  .parallax-section h2 {
    font-size: 2.2rem;
  }

  .parallax-section p {
    font-size: 1rem;
  }

  .cta-section h2 {
    font-size: 2.2rem;
  }

  .cta-section p {
    font-size: 1rem;
  }
}
