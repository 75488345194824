/* File: src/components/PartnersSection.css */

/* Importing DM Serif Display Font */
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");

/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "DM Serif Display", serif;
}

/* Root Variables for Consistent Styling */
:root {
  --primary-color: #00d8a7;
  --secondary-color: #005bea;
  --background-color-dark: #071d44;
  --background-color-light: #fefefe;
  --text-color: #333333;
  --heading-color: #071d44;
  --subheading-color: #555555;
  --highlight-color: #00796b;
  --button-gradient: linear-gradient(45deg, #00d8a7, #005bea);
  --button-hover-gradient: linear-gradient(45deg, #005bea, #00d8a7);
  --transition-speed: 0.3s;
  --logo-size: 150px; /* Standardized logo size */
  --logo-spacing: 20px; /* Space between logos */
  --shadow-light: 0 4px 12px rgba(0, 0, 0, 0.1);
  --shadow-medium: 0 6px 15px rgba(0, 0, 0, 0.2);
  --shadow-heavy: 0 10px 20px rgba(0, 0, 0, 0.25);
}

/* Page Container Styles */
.page-container {
  background-color: var(
    --background-color-dark
  ); /* Dark background for the page */
  padding: 50px 20px; /* Padding to create space around the lighter section */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Partners Container Styles */
.partners-container {
  padding: 75px 100px 50px;
  background-color: var(
    --background-color-light
  ); /* Lighter background for the Brands section */
  border-radius: 15px; /* Rounded corners for better integration */
  text-align: center;
  font-family: "DM Serif Display", serif;
  box-shadow: var(--shadow-light); /* Subtle shadow for depth */
  max-width: 1200px;
  width: 100%;
}

.partners-container .highlight {
  color: var(--highlight-color); /* Subtle teal for harmonious contrast */
}

/* Heading Styles */
.partners-section__heading {
  font-size: 2rem;
  color: var(
    --text-color
  ); /* Slightly darker text to contrast with the lighter background */
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  font-weight: 700;
}

.partners-section__heading::after {
  content: "";
  display: block;
  width: 0;
  height: 4px;
  background: var(--highlight-color);
  margin: 10px auto 0;
  transition: width var(--transition-speed) ease;
}

.partners-section__heading:hover::after {
  width: 50%;
}

/* Partners Grid Styles */
.partners-section__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 40px;
  justify-items: center;
  align-items: center;
}

/* Partner Card Styles */
.partner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--logo-size);
  height: var(--logo-size);
  padding: 10px;
  transition: transform var(--transition-speed) ease,
    box-shadow var(--transition-speed) ease,
    border-color var(--transition-speed) ease;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #ffffff;
}

.partner:hover,
.partner:focus {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: var(--shadow-medium);
  border-color: var(--highlight-color);
}

.partner:focus {
  outline: 3px solid var(--highlight-color);
  outline-offset: 4px;
}

/* Partner Logo Styles */
.partners-section__logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.partner:hover .partners-section__logo,
.partner:focus .partners-section__logo {
  filter: grayscale(0%); /* Remove grayscale on hover to show color */
  transform: scale(1.08);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .partners-container {
    padding: 60px 80px 50px;
  }

  .partners-section__heading {
    font-size: 1.8rem;
  }

  .partners-section__grid {
    gap: 30px;
  }

  :root {
    --logo-size: 140px;
    --logo-spacing: 15px;
  }

  .partner {
    width: var(--logo-size);
    height: var(--logo-size);
  }
}

@media (max-width: 992px) {
  .partners-container {
    padding: 50px 60px 50px;
  }

  .partners-section__heading {
    font-size: 1.6rem;
  }

  .partners-section__grid {
    gap: 25px;
  }

  :root {
    --logo-size: 130px;
  }

  .partner {
    width: var(--logo-size);
    height: var(--logo-size);
  }
}

@media (max-width: 768px) {
  .partners-container {
    padding: 40px 40px 50px;
  }

  .partners-section__heading {
    font-size: 1.5rem;
  }

  .partners-section__grid {
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  :root {
    --logo-size: 120px;
  }

  .partner {
    width: var(--logo-size);
    height: var(--logo-size);
  }
}

@media (max-width: 576px) {
  .partners-container {
    padding: 30px 20px 50px;
  }

  .partners-section__heading {
    font-size: 1.4rem;
  }

  .partners-section__grid {
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }

  :root {
    --logo-size: 100px;
  }

  .partner {
    width: var(--logo-size);
    height: var(--logo-size);
  }

  .partners-section__heading::after {
    height: 2px; /* Thinner underline on smaller screens */
  }
}
