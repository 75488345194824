/* File: src/components/Navbar.css */

/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--primary-font); /* Use the primary font variable */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Define Color and Font Variables */
:root {
  --primary-font: 'Arial', sans-serif; /* Primary font variable */
  --white: #FFFFFF;
  --text-color: #177DAD;
  --greyish-white: #f0f0f0; /* Light grey for scrolled state */
  --hover-color: #e0f0ff; /* Light blueish for hover */
  --active-link-color: #177DAD; /* Deep blue for active links */
  --submenu-bg: var(--white);
  --submenu-text: var(--text-color);
  --navbar-shadow: rgba(0, 0, 0, 0.1);
  --focus-outline: #177DAD;
}

/* Navbar Styles */
.navbar {
  width: 100%;
  background: var(--white);
  padding: 20px 30px; /* Increased padding to accommodate larger logo */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: background 0.3s ease, padding 0.3s ease;
  box-shadow: 0 4px 6px var(--navbar-shadow);
}

.navbar--scrolled {
  background: var(--greyish-white);
  padding: 15px 30px; /* Adjusted padding for scrolled state */
  backdrop-filter: blur(5px);
}

.navbar__container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the menu items */
  position: relative;
}

/* Logo */
.navbar__logo {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.navbar__logo img {
  height: 100px; /* Increased logo size for desktop */
  width: auto;
  transition: height 0.3s ease;
}

.navbar--scrolled .navbar__logo img {
  height: 90px; /* Adjusted logo size when scrolled */
}

/* Hamburger Icon */
.navbar__hamburger {
  display: none; /* Hidden on desktop */
  font-size: 1.8rem;
  color: var(--text-color);
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.navbar__hamburger:focus {
  outline: 2px solid var(--focus-outline);
  outline-offset: 2px;
}

/* Menu Items */
.navbar__menu {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center; /* Center the menu items */
  flex: 1;
  margin-left: 150px;
}

.navbar__menu--active {
  right: 0;
}

/* Individual Menu Item */
.navbar__item {
  position: relative;
  margin: 0 15px;
}

/* Menu Links */
.navbar__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 4px;
  transition: background 0.3s ease, color 0.3s ease;
}

.navbar__link:hover,
.navbar__link--active {
  background: var(--hover-color);
  color: var(--active-link-color);
}

/* Icons in Menu */
.navbar__icon {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Solutions Button */
.navbar__solutions-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: var(--text-color);
  font-weight: 600;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 4px;
  transition: background 0.3s ease, color 0.3s ease;
  font-family: inherit; 
}

.navbar__solutions-button:hover,
.navbar__solutions-button:focus {
  background: var(--hover-color);
  color: var(--active-link-color);
}

.navbar__solutions-button:focus {
  outline: 2px solid var(--focus-outline);
  outline-offset: 2px;
}

.navbar__dropdown-icon {
  margin-left: 5px;
  font-size: 0.9rem;
  transition: transform 0.3s ease;
}

/* Submenu Dropdown */
.navbar__submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--submenu-bg);
  min-width: 250px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 10px 0;
  border-radius: 4px;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.navbar__submenu--open {
  display: block;
  opacity: 1;
}

.navbar__subitem {
  padding: 0;
}

.navbar__sublink {
  display: block;
  padding: 8px 20px;
  text-decoration: none;
  color: var(--submenu-text);
  transition: background 0.3s ease, color 0.3s ease;
}

.navbar__sublink:hover,
.navbar__sublink--active {
  background: var(--hover-color);
  color: var(--active-link-color);
}

/* Contact Button Styled as a Button */
.navbar__contact-text {
  /* Additional styling if needed */
}

/* Contact Button Specific Styling */
.navbar__link--button {
  background: var(--text-color);
  color: var(--white);
  padding: 8px 16px;
  border-radius: 50px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.navbar__link--button:hover,
.navbar__link--button:focus {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.navbar__link--button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar__link--button:focus {
  outline: 2px solid var(--focus-outline);
  outline-offset: 2px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Show Hamburger Icon */
  .navbar__hamburger {
    display: block;
  }

  /* Mobile Menu */
  .navbar__menu {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 80%;
    max-width: 300px;
    background: var(--greyish-white);
    flex-direction: column;
    align-items: flex-start;
    padding: 80px 20px;
    transition: right 0.3s ease;
    z-index: 998;
    overflow-y: auto;
  }

  .navbar__menu--active {
    right: 0;
  }

  /* Menu Links */
  .navbar__link,
  .navbar__solutions-button {
    color: var(--text-color);
    width: 100%;
    padding: 12px 0;
    font-size: 1.1rem;
  }

  /* Submenu Positioning */
  .navbar__submenu {
    position: static;
    background: var(--greyish-white);
    box-shadow: none;
    padding: 0;
    min-width: 100%;
  }

  .navbar__sublink {
    color: var(--text-color);
    padding: 8px 20px;
  }

  .navbar__sublink:hover,
  .navbar__sublink--active {
    background: var(--hover-color);
    color: var(--active-link-color);
  }

  /* Show Submenu when Open */
  .navbar__submenu--open {
    display: block;
    opacity: 1;
  }

  /* Rotate Dropdown Icon */
  .navbar__dropdown-icon {
    margin-left: auto;
    transition: transform 0.3s ease;
  }

  .navbar__item--dropdown .navbar__dropdown-icon {
    transform: rotate(0deg);
  }

  .navbar__item--dropdown.navbar__item--active .navbar__dropdown-icon {
    transform: rotate(180deg);
  }

  /* Contact Button Styling in Mobile */
  .navbar__link--button {
    width: 100%;
    justify-content: center;
  }

  /* Increase Navbar Container Size on Small Screens */
  .navbar__container {
    padding: 25px 30px; /* Increased padding */
    justify-content: center; /* Ensure menu remains centered */
  }

  /* Adjust Logo Size for Mobile */
  .navbar__logo img {
    height: 90px; /* Further increased logo size for mobile */
  }

  .navbar--scrolled .navbar__logo img {
    height: 75px; /* Adjust logo size when scrolled on mobile */
  }

  /* Adjust Navbar Padding for Mobile */
  .navbar {
    padding: 25px 30px; /* Increased padding for larger logo */
  }

  .navbar--scrolled {
    padding: 20px 30px; /* Adjusted padding for scrolled state */
  }
}

/* Smooth Hover Underline Effect */
/* .navbar__link::after,
.navbar__sublink::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--active-link-color);
  transition: width 0.3s;
} */

.navbar__link:hover::after,
.navbar__sublink:hover::after,
.navbar__link--active::after,
.navbar__sublink--active::after {
  width: 100%;
}

/* Accessibility Focus States */
.navbar__link:focus,
.navbar__solutions-button:focus,
.navbar__sublink:focus,
.navbar__contact-button:focus {
  outline: 2px solid var(--focus-outline);
  outline-offset: 2px;
}
