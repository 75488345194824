/* ContactUsPage.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600&display=swap');

/* CSS Variables for Consistent Theming */
:root {
  --primary-color: #00D8A7;
  --secondary-color: #005bea;
  --background-color: #071D44;
  --text-color: #ffffff;
  --accent-color: #b0c4de;
  --heading-font: 'Titillium Web', sans-serif;
  --body-font: 'Titillium Web', sans-serif;
  --success-color: #28a745;
  --error-color: #dc3545;
}

/* General Page Styling */
body {
  font-family: var(--body-font);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact-us-page {
  background-color: var(--background-color);
  padding: 50px 20px;
  width: 100%;
  color: var(--text-color);
}

/* Container */
.contact-us-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/* Content Box */
.contact-us-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  background: linear-gradient(135deg, #F0F0F0 0%, #E8E8E8 100%);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

@media (min-width: 768px) {
  .contact-us-content {
    flex-direction: row;
    gap: 80px;
  }
}

/* Contact Info Section */
.contact-info {
  flex: 1;
}

.contact-us-heading {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-us-description {
  color: #333;
  font-size: 1.125rem;
  margin-bottom: 30px;
  line-height: 1.6;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.contact-item:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.contact-icon {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-right: 15px;
}

.contact-text h4 {
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #333;
}

.contact-text p {
  font-size: 0.95rem;
  color: #555;
}

/* Form Section */
.contact-form {
  flex: 1;
  background-color: var(--background-color);
  padding: 40px;
  border-radius: 12px;
  border: 2px solid var(--primary-color);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 25px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  align-self: flex-start;
  margin-bottom: 8px;
  font-weight: 600;
  color: var(--primary-color);
  font-size: 1rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px 20px;
  border: 1px solid #b0c4de;
  border-radius: 8px;
  background-color: #333;
  color: #fff;
  font-size: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #b0c4de;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 15px rgba(0, 216, 167, 0.5);
  outline: none;
}

textarea {
  min-height: 150px;
  resize: vertical;
}

/* Centered Button Styling */
.send-message-button {
  padding: 12px 30px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  border: none;
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  display: block;
  margin: 20px auto 0 auto;
}

.send-message-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.3);
}

.send-message-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Feedback Messages */
.feedback-message {
  margin-top: 15px;
  font-size: 0.95rem;
  text-align: center;
}

.success-message {
  color: var(--success-color);
}

.error-message {
  color: var(--error-color);
}

/* Map Styling */
.map-container {
  width: 100%;
  margin-top: 50px;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Responsive Adjustments */
@media (max-width: 767px) {
  .contact-us-container {
    padding: 20px;
  }

  .contact-us-heading {
    font-size: 2rem;
  }

  .contact-us-description {
    font-size: 1rem;
  }

  .contact-form {
    padding: 30px;
  }

  .send-message-button {
    width: 100%;
    max-width: 300px;
  }
}

@media (min-width: 1200px) {
  .contact-us-heading {
    font-size: 3rem;
  }

  .contact-form {
    padding: 50px;
  }

  .send-message-button {
    font-size: 1.1rem;
    padding: 15px 35px;
  }
}
