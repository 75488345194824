/* src/pages/TermsAndConditions.css */

.terms-section {
    padding: 80px 20px;
    background-color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .terms-section h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    color: #122546;
  }
  
  .content {
    line-height: 1.6;
  }
  
  .main-list {
    counter-reset: section;
  }
  
  .main-list > li {
    margin-bottom: 30px;
    padding-left: 30px;
    position: relative;
  }
  
  .main-list > li::before {
    counter-increment: section;
    content: counter(section) ".";
    position: absolute;
    left: 0;
    top: 0;
    font-weight: bold;
    color: #00d8a7;
    font-size: 1.2rem;
  }
  
  .main-list h2 {
    font-size: 1.5rem;
    color: #122546;
    margin-bottom: 10px;
  }
  
  .sub-list {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .sub-list ul {
    list-style-type: circle;
    margin-left: 20px;
  }
  
  .sub-list li {
    margin-bottom: 10px;
  }
  
  .sub-list li strong {
    color: #122546;
  }
  
  @media (max-width: 768px) {
    .terms-section {
      padding: 40px 15px;
    }
  
    .terms-section h1 {
      font-size: 2rem;
      margin-bottom: 30px;
    }
  
    .main-list > li {
      padding-left: 25px;
      margin-bottom: 25px;
    }
  
    .main-list > li::before {
      font-size: 1rem;
    }
  
    .main-list h2 {
      font-size: 1.3rem;
    }
  
    .sub-list {
      margin-left: 15px;
    }
  
    .sub-list ul {
      margin-left: 15px;
    }
  
    .sub-list li {
      margin-bottom: 8px;
    }
  }
  
  @media (max-width: 480px) {
    .terms-section {
      padding: 30px 10px;
    }
  
    .terms-section h1 {
      font-size: 1.8rem;
      margin-bottom: 25px;
    }
  
    .main-list > li {
      padding-left: 20px;
      margin-bottom: 20px;
    }
  
    .main-list > li::before {
      font-size: 0.9rem;
    }
  
    .main-list h2 {
      font-size: 1.2rem;
    }
  
    .sub-list {
      margin-left: 10px;
    }
  
    .sub-list ul {
      margin-left: 10px;
    }
  
    .sub-list li {
      margin-bottom: 6px;
    }
  }
  