/* src/WhyChooseUsSection.css */

/* Root Variables for Consistent Styling */
:root {
  /* Primary and Secondary Colors */
  --primary-color: #005bea; /* Subdued blue for headings */
  --secondary-color: #00d8a7; /* Teal for accents */
  
  /* Background and Text Colors */
  --background-color: #f0f4f8; /* Light background */
  --heading-color: #333333; /* Dark gray for main headings */
  --subheading-color: #555555; /* Medium gray for subheadings */
  --text-color: #444444; /* Standard text color */
  
  /* Button Colors */
  --button-background: #00d8a7; /* Teal for buttons */
  --button-hover-background: #00b392; /* Darker teal on hover */
  
  /* Other Variables */
  --transition-speed: 0.3s;
  --logo-max-width: 150px;
  --logo-max-height: 100px;
}

.why-choose-us-section {
  padding: 30px 50px;
  background-color: var(--background-color); /* Updated to light background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.why-choose-us-heading {
  color: var(--primary-color); /* Changed to primary color for prominence */
  font-size: 2rem;
  margin-bottom: 10px;
}

.why-choose-us-subheading {
  color: var(--subheading-color); /* Darker shade for better readability */
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.why-choose-us-content {
  display: grid;
  grid-template-areas:
    "top-left top-center top-right"
    "bottom-left center bottom-right"
    "bottom-center bottom-center bottom-center";
  gap: 20px;
  align-items: center;
  justify-items: center;
  width: 100%;
}

.why-choose-us-text {
  max-width: 200px;
  color: var(--text-color); /* Changed to standard text color */
}

.why-choose-us-text h3 {
  font-size: 1rem;
  margin-bottom: 5px;
  color: var(--secondary-color); /* Maintained for accent */
}

.why-choose-us-text p {
  font-size: 0.875rem;
  margin-top: 0;
  color: #666666; /* Darker gray for improved contrast */
}

.why-choose-us-text.top-left {
  grid-area: top-left;
  text-align: left;
}

.why-choose-us-text.top-right {
  grid-area: top-right;
  text-align: right;
}

.why-choose-us-text.bottom-left {
  grid-area: bottom-left;
  text-align: left;
}

.why-choose-us-text.bottom-right {
  grid-area: bottom-right;
  text-align: right;
}

.why-choose-us-center {
  grid-area: center;
  justify-self: start; /* Maintained positioning */
  position: relative; /* For absolute positioning of the image */
}

.why-choose-us-center img {
  max-width: 50%;
  height: auto;
  /* Removed margin-left and added transform for precise positioning */
  transform: translate(10px, -120px); /* Shift right by 20px and up by 20px */
  transition: transform var(--transition-speed) ease;
}

.why-choose-us-text.bottom-center {
  grid-area: bottom-center;
  text-align: center;
}

.buy-now-button {
  padding: 10px 20px;
  background-color: var(--button-background); /* Updated to variable */
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px; /* Increased top margin for better spacing */
  transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
}

.buy-now-button:hover {
  background-color: var(--button-hover-background); /* Smooth color transition on hover */
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.buy-now-button:active {
  transform: translateY(0);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 1200px) {
  :root {
    --logo-max-width: 140px;
    --logo-max-height: 90px;
  }
}

@media (max-width: 992px) {
  :root {
    --logo-max-width: 120px;
    --logo-max-height: 80px;
  }
}

@media (max-width: 768px) {
  :root {
    --logo-max-width: 130px;
    --logo-max-height: 85px;
  }
  
  .why-choose-us-content {
    grid-template-areas:
      "top-left"
      "top-right"
      "center"
      "bottom-left"
      "bottom-right"
      "bottom-center";
    gap: 15px;
  }
  
  .why-choose-us-center img {
    max-width: 70%; /* Resize image on smaller screens */
    transform: translate(10px, -10px); /* Adjust shift for smaller screens */
  }

  .why-choose-us-section {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .why-choose-us-heading {
    font-size: 1.5rem;
  }

  .why-choose-us-subheading {
    font-size: 1rem;
  }

  .why-choose-us-content {
    gap: 10px;
  }

  .why-choose-us-center img {
    max-width: 90%;
    transform: translate(0px, -10px); /* Further adjust shift for very small screens */
  }
  
  .buy-now-button {
    padding: 12px 24px;
    font-size: 0.95rem;
  }
}
