/* src/pages/PrivacyPolicy.css */

.privacy-section {
  padding: 80px 20px;
  background-color: #f9f9f9;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.privacy-section h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #122546;
}

.content {
  line-height: 1.6;
}

/* Styles for the main content sections */
.main-content .section {
  margin-bottom: 30px;
}

/* Adjust h2 styles */
.main-content h2 {
  font-size: 1.5rem;
  color: #122546;
  margin-bottom: 10px;
}

/* Sub-list styles */
.sub-list {
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 20px;
}

.nested-sub-list {
  list-style-type: circle;
  margin-left: 20px;
  padding-left: 20px;
}

.sub-list li,
.nested-sub-list li {
  margin-bottom: 10px;
}

.sub-list li strong,
.nested-sub-list li strong {
  color: #122546;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .privacy-section {
    padding: 40px 15px;
  }

  .privacy-section h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .main-content h2 {
    font-size: 1.3rem;
  }

  .sub-list,
  .nested-sub-list {
    margin-left: 15px;
    padding-left: 15px;
  }

  .sub-list li,
  .nested-sub-list li {
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .privacy-section {
    padding: 30px 10px;
  }

  .privacy-section h1 {
    font-size: 1.8rem;
    margin-bottom: 25px;
  }

  .main-content h2 {
    font-size: 1.2rem;
  }

  .sub-list,
  .nested-sub-list {
    margin-left: 10px;
    padding-left: 10px;
  }

  .sub-list li,
  .nested-sub-list li {
    margin-bottom: 6px;
  }
}
