@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&family=DM+Serif+Display&display=swap");

/* Base Styles (Mobile First) */
.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #0c1d3c;
  font-family: "DM Serif Display", serif;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  text-align: center;
}

.hero-container::before,
.hero-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-container::before {
  height: 200%;
  background-image: url("/public/images/hero-background.jpg");
  opacity: 0.4;
  transform: translateZ(-1px) scale(2);
}

.hero-container::after {
  height: 300%;
  background-image: url("/public/images/hero-background.jpg");
  opacity: 0.2;
  transform: translateZ(-0.5px) scale(1.5);
}

.hero-text {
  color: white;
  z-index: 1;
  margin: 20px 0;
  width: 100%;
}

.hero-text h1,
.hero-text h2 {
  font-size: clamp(1.5rem, 4vw, 2.75rem);
  margin: 0.5em 0;
}

.hero-text p {
  font-size: clamp(0.875rem, 2vw, 1.25rem);
  margin: 10px 0;
  line-height: 1.6;
}

.changing-text {
  color: #00d8a7;
  border-right: 2px solid;
  white-space: nowrap;
  overflow: hidden;
}

.learn-more-button {
  padding: 0.75em 1.5em;
  background: linear-gradient(45deg, #00d8a7, #00a5d6);
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-top: 20px;
  font-family: "Titillium Web", sans-serif;
}

.learn-more-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.learn-more-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hero-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  animation: slideIn 1s ease-out;
}

.hero-image img {
  width: 80%;
  max-width: 300px;
  height: auto;
}

/* Larger Screens */
@media (min-width: 768px) {
  .hero-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 40px;
  }

  .hero-text {
    flex: 1;
    text-align: left;
    margin: 0 20px;
  }

  .hero-image {
    flex: 1;
    justify-content: flex-end;
    margin-right: 20px;
  }

  .hero-image img {
    max-width: 100%;
  }
}

/* Extra Large Screens */
@media (min-width: 1200px) {
  .hero-container {
    padding: 60px 100px;
  }

  .hero-text h1,
  .hero-text h2 {
    font-size: clamp(2rem, 3vw, 3rem);
  }

  .hero-text p {
    font-size: clamp(1rem, 1.5vw, 1.25rem);
  }

  .learn-more-button {
    padding: 12px 24px;
    font-size: 1.1rem;
  }

  .hero-image img {
    max-width: 100%;
  }
}
