@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600&display=swap');

.services-page {
  font-family: 'Titillium Web', sans-serif;
  color: #333;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Hero Section */
.hero-section {
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 34, 74, 0.7); /* Dark blue overlay */
}

.hero-content {
  position: relative;
  text-align: center;
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 20px;
}

.hero-content h1 {
  font-size: 4rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.5rem;
}

/* Parallax Section */
.parallax-section {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallax-content h2 {
  font-size: 3rem;
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Features and Benefits Section */
.section {
  padding: 60px 20px;
}

.section.light-bg {
  background-color: #f5f7fa; /* Light gray-blue background */
}

.section.dark-bg {
  background-color: #fff;
}

.section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
  color: #08224a; /* Dark blue */
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
  transition: transform 0.3s;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.card:hover {
  transform: translateY(-10px);
}

.card.light-bg {
  background-color: #fff;
}

.card.dark-bg {
  background-color: #f5f7fa;
}

.card-icon {
  font-size: 3rem;
  margin-bottom: 20px;
}

.card-icon.blue {
  color: #005bea;
}

.card-icon.teal {
  color: #00d8a7;
}

.card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #08224a;
}

.card p {
  font-size: 1rem;
  color: #333;
}

/* Call to Action */
.cta-section {
  background-color: #08224a;
  color: #fff;
  text-align: center;
  padding: 60px 20px;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #00d8a7;
  color: #fff;
  padding: 15px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #00b38f;
}

/* Responsive Styles */
/* Large Devices (desktops) */
@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: 3.5rem;
  }

  .hero-content p {
    font-size: 1.3rem;
  }

  .parallax-content h2 {
    font-size: 2.7rem;
  }

  .section h2 {
    font-size: 2.2rem;
  }

  .card h3 {
    font-size: 1.4rem;
  }

  .cta-section h2 {
    font-size: 2.2rem;
  }

  .cta-button {
    font-size: 1.1rem;
  }
}

/* Tablets and Small Laptops */
@media (max-width: 992px) {
  .hero-content h1 {
    font-size: 3rem;
  }

  .hero-content p {
    font-size: 1.2rem;
  }

  .parallax-content h2 {
    font-size: 2.5rem;
  }

  .section h2 {
    font-size: 2rem;
  }

  .card h3 {
    font-size: 1.3rem;
  }

  .cta-section h2 {
    font-size: 2rem;
  }

  .cta-button {
    font-size: 1rem;
  }
}

/* Tablets and Large Phones */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .parallax-content h2 {
    font-size: 2rem;
  }

  .section h2 {
    font-size: 1.8rem;
  }

  .card h3 {
    font-size: 1.2rem;
  }

  .cta-section h2 {
    font-size: 1.8rem;
  }

  .cta-button {
    font-size: 0.9rem;
  }
}

/* Mobile Devices */
@media (max-width: 576px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 0.9rem;
  }

  .parallax-content h2 {
    font-size: 1.7rem;
  }

  .section h2 {
    font-size: 1.5rem;
  }

  .card h3 {
    font-size: 1.1rem;
  }

  .cta-section h2 {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 0.85rem;
  }
}
