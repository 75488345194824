.footer {
  background-color: #f9f9f9; /* Very light grey background */
  padding: 60px 50px 40px; /* Increased padding for spaciousness */
  color: #555; /* Dark grey text color for readability */
  font-family: 'Helvetica Neue', sans-serif; /* Modern, clean font */
}

.footer-top {
  display: flex;
  flex-wrap: wrap; /* Wrap items on smaller screens */
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #e0e0e0; /* Light grey divider */
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.footer-logo img {
  height: 75px;
  margin-right: 15px;
}

.footer-logo span {
  font-size: 1.8rem;
  font-weight: 700;
  color: #177DAD; /* Accent color */
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Use gap for consistent spacing */
}

.footer-nav a {
  color: #555;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-nav a:hover {
  color: #177DAD; /* Accent color on hover */
}

.footer-social {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.footer-social a {
  color: #555;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #177DAD;
}

.footer-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.footer-bottom a {
  color: #555;
  margin: 5px 0;
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.3s ease;
}

.footer-bottom a:hover {
  color: #177DAD;
}

.footer-bottom span {
  font-size: 0.875rem;
  color: #999;
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
  .footer-top,
  .footer-bottom {
      flex-direction: column;
      align-items: flex-start;
  }

  .footer-logo {
      margin-bottom: 30px;
  }

  .footer-nav {
      flex-direction: column;
      gap: 10px;
  }

  .footer-social {
      margin-top: 30px;
  }

  .footer-bottom {
      margin-top: 30px;
  }
}
