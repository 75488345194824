/* src/components/InfrastructureService.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

/* General Styles */
.infra-container {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #122546 30%, #1E3557 100%);
  color: #FFFFFF;
  padding: 60px 20px;
  max-width: 1200px;
  margin: 100px auto 0 auto;
  transition: all 0.3s ease-in-out;
}

/* Typography */
.infra-header {
  color: #FFFFFF;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 40px;
  position: relative;
  animation: slideInDown 1s ease-in-out;
}

.infra-header::after {
  content: '';
  width: 80px;
  height: 5px;
  background-color: #00D8A7;
  display: block;
  margin: 15px auto 0 auto;
  border-radius: 2px;
}

.infra-intro p {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 60px auto;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #FFFFFF;
  animation: fadeIn 1.2s ease-in-out;
}

.infra-about {
  max-width: 800px;
  margin: 0 auto 60px auto;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #FFFFFF;
  animation: fadeIn 1.2s ease-in-out;
  text-align: center;
}

/* Sections */
.infra-section p {
  margin-bottom: 80px;
  color: #FFFFFF;
  animation: fadeInUp 1.2s ease-in-out;
}

.infra-section-title {
  color: #FFFFFF;
  font-size: 2.5rem;
  margin-bottom: 50px;
  text-align: center;
  position: relative;
}

.infra-section-title::after {
  content: '';
  width: 60px;
  height: 4px;
  background-color: #00D8A7;
  display: block;
  margin: 15px auto 0 auto;
  border-radius: 2px;
}

/* Services and Benefits Grid */
.infra-services,
.infra-benefits {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Always 2 columns */
  gap: 40px;
  padding: 0 20px;
}

/* Cards */
.infra-card,
.infra-benefit-card {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  color: #FFFFFF;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.infra-card::before,
.infra-benefit-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 0;
  height: 0;
  background: rgba(0, 216, 167, 0.2);
  transform: rotate(45deg);
  transition: all 0.5s ease;
}

.infra-card:hover::before,
.infra-benefit-card:hover::before {
  width: 200%;
  height: 200%;
}

.infra-card:hover,
.infra-benefit-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6);
}

/* Icons */
.infra-card-icon,
.infra-benefit-icon {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #00D8A7;
  animation: bounce 2s infinite;
}

.infra-card-title,
.infra-benefit-title {
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-bottom: 15px;
  text-align: center;
}

.infra-card-description,
.infra-benefit-description {
  font-size: 1rem;
  color: #E0E0E0;
  text-align: center;
  margin-bottom: 20px;
}

/* Features List */
.infra-card-features {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.infra-card-features li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #FFFFFF;
  font-size: 1rem;
}

.feature-icon {
  color: #00D8A7;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.feature-icon:hover {
  transform: rotate(20deg) scale(1.2);
}

/* Button */
.infra-button {
  display: inline-block;
  background: linear-gradient(45deg, #00D8A7, #00B38E);
  color: #FFFFFF;
  padding: 18px 36px;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.infra-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Centered Elements */
.center {
  text-align: center;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%,
  20%,
  60%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  80% {
    transform: translateY(-7px);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .infra-services,
  .infra-benefits {
    grid-template-columns: 1fr; /* Switch to 1 column on smaller screens */
  }

  .infra-header {
    font-size: 2.5rem;
  }

  .infra-section-title {
    font-size: 2rem;
  }

  .infra-card,
  .infra-benefit-card {
    padding: 20px;
  }

  .infra-button {
    padding: 15px 30px;
    font-size: 1rem;
  }
}
