/* Root Variables for Consistent Styling */
:root {
  --primary-color: #00d8a7;
  --secondary-color: #005bea;
  --background-color: #ffffff;
  --text-color: #333333;
  --heading-color: #071d44;
  --subheading-color: #555555;
  --button-gradient: linear-gradient(45deg, #00d8a7, #005bea);
  --button-hover-gradient: linear-gradient(45deg, #005bea, #00d8a7);
  --transition-speed: 0.3s;
  --logo-size: 180px; /* Standardized logo size */
  --logo-spacing: 30px; /* Space between logos */
}

.brands-section {
  padding: 60px 20px;
  background-color: var(--background-color);
  text-align: center;
}

.brands-header {
  max-width: 800px;
  margin: 0 auto 40px;
}

.brands-header h2 {
  color: var(--heading-color);
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 600;
}

.brands-header p {
  color: var(--subheading-color);
  font-size: 1.1rem;
  line-height: 1.6;
}

.carousel-wrapper {
  position: relative;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--logo-spacing);
  height: 200px; /* Increased height for better visibility */
}

.brand-slide a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--logo-size);
  height: var(--logo-size);
  transition: transform var(--transition-speed) ease;
}

.brand-slide a:hover,
.brand-slide a:focus {
  transform: scale(1.05);
  outline: none;
}

.brand-logo {
  width: auto;
  height: 160px;
  object-fit: contain;
  filter: brightness(1.2) contrast(1.1);
  transition: filter var(--transition-speed) ease,
    transform var(--transition-speed) ease;
}

.brand-slide a:hover .brand-logo,
.brand-slide a:focus .brand-logo {
  transform: scale(1.1);
  filter: brightness(1.4) contrast(1.2);
}

.know-more-button {
  margin-top: 30px;
  padding: 14px 28px;
  background: var(--button-gradient);
  border: none;
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transition: background var(--transition-speed) ease,
    transform var(--transition-speed) ease,
    box-shadow var(--transition-speed) ease;
  text-decoration: none; /* Ensure link looks like a button */
}

.know-more-button .button-icon {
  margin-left: 8px;
  transition: transform var(--transition-speed) ease;
}

.know-more-button:hover {
  background: var(--button-hover-gradient);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

.know-more-button:active {
  transform: translateY(0);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Remove any default link styles */
.brand-slide a {
  text-decoration: none;
}

/* Adjust marquee behavior */
.react-fast-marquee {
  display: flex;
  align-items: center;
}

/* Responsive Design */
@media (max-width: 1200px) {
  :root {
    --logo-size: 160px;
    --logo-spacing: 25px;
  }
}

@media (max-width: 992px) {
  :root {
    --logo-size: 140px;
    --logo-spacing: 20px;
  }
}

@media (max-width: 768px) {
  :root {
    --logo-size: 130px;
    --logo-spacing: 15px;
  }
}

@media (max-width: 576px) {
  .brands-header h2 {
    font-size: 2rem;
  }

  .brands-header p {
    font-size: 1rem;
  }

  :root {
    --logo-size: 110px;
    --logo-spacing: 10px;
  }

  .know-more-button {
    padding: 12px 24px;
    font-size: 0.95rem;
  }
}
