/* SynologyPage.css */

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

/* Set the entire page background to white */
body, html {
  background-color: #ffffff; /* Changed to white */
  margin: 0;
  padding: 0;
}

.synology-page-container {
  background-color: #ffffff; /* Match the page background */
  padding-bottom: 50px;
  font-family: 'DM Serif Display', serif;
}

.synology-hero-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0 50px;
  background-color: #0c1d3c; /* Changed to white */
  color: #0c1d3c; /* Dark text for readability */
  overflow: hidden;
  perspective: 1px;
}

.synology-hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  background: url('/public/images/hero-background.jpg') no-repeat center center/cover; /* Corrected path */
  opacity: 0.;
  transform: translateZ(-1px) scale(2);
  z-index: 0;
}

.synology-hero-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300%;
  background: url('/public/images/hero-background.jpg') no-repeat center center/cover; /* Corrected path */
  opacity: 0.2;
  transform: translateZ(-0.5px) scale(1.5);
  z-index: -1;
}

.hero-text {
  position: relative;
  flex: 1;
  text-align: left;
  z-index: 1;
  margin-top: 60px;
  margin-left: 60px;
}

.hero-text h1 {
  font-size: 2.75rem;
  margin: 0;
  color: #00D8A7; /* Highlight color */
}

.hero-text h2 {
  font-size: 2.75rem;
  margin: 10px 0 20px 0;
  color: #f9f9fa; /* Dark text */
}

.hero-text p {
  font-size: 1.02rem;
  margin: 10px 0;
  line-height: 1.6;
  color: #0c1d3c; /* Dark text */
}

.learn-more-button {
  padding: 12px 24px;
  background: linear-gradient(45deg, #00D8A7, #005bea);
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-top: 20px;
  font-family: "Titillium Web", sans-serif;
  display: inline-flex;
  align-items: center;
}

.learn-more-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.learn-more-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hero-image {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  animation: slideIn 1s ease-out;
}

.hero-image img {
  max-width: 80%;
  height: auto;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Styling for the video container */
.video-container {
  margin-top: 50px;
  padding: 0;
  background-color: #ffffff; /* Changed to white */
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  margin: 50px auto; /* Center the container */
}

.video-container iframe {
  border: none;
  border-radius: 0;
  width: 100%;
  height: 400px; /* Height set to 400px */
  display: block;
  margin: 0;
  padding: 0;
}

/* Product Carousel Section */
.product-carousel-section {
  padding: 60px 50px;
  background-color: #ffffff; /* Changed to white */
  color: #0c1d3c; /* Dark text */
}

.carousel-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
  color: #00D8A7; /* Accent color */
}

.product-card {
  background-color: #f9f9f9; /* Light background for contrast */
  border: 1px solid #e0e0e0; /* Subtle border */
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin: 0 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.product-image-container {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.product-name {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #00D8A7; /* Accent color */
}

.product-description {
  font-size: 0.95rem;
  color: #0c1d3c; /* Dark text */
  line-height: 1.4;
}

/* Slick Carousel Dots Customization */
.slick-dots li button:before {
  color: #00D8A7; /* Dot color */
}

.slick-dots li.slick-active button:before {
  color: #0c1d3c; /* Active dot color */
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .synology-hero-container {
    padding: 0 30px;
  }

  .hero-text {
    margin-left: 30px;
  }

  .product-carousel-section {
    padding: 40px 30px;
  }

  .carousel-title {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }

  .product-card {
    padding: 15px;
  }

  .product-name {
    font-size: 1.1rem;
  }

  .product-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .synology-hero-container {
    flex-direction: column;
    height: auto;
    padding: 40px 20px;
  }

  .hero-text {
    margin: 0;
    text-align: center;
  }

  .hero-image {
    margin-top: 30px;
  }

  .product-carousel-section {
    padding: 40px 20px;
  }

  .product-image-container {
    height: 120px;
  }
}

@media (max-width: 768px) {
  .carousel-title {
    font-size: 1.6rem;
    margin-bottom: 25px;
  }

  .product-image-container {
    height: 100px;
  }

  .product-description {
    font-size: 0.85rem;
  }
}

@media (max-width: 576px) {
  .synology-hero-container {
    padding: 30px 10px;
  }

  .hero-text h1 {
    font-size: 2.3rem;
  }

  .hero-text h2 {
    font-size: 2.3rem;
  }

  .hero-text p {
    font-size: 1rem;
  }

  .hero-image img {
    max-width: 70%;
  }

  .carousel-title {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }

  .product-card {
    padding: 10px;
  }

  .product-name {
    font-size: 1rem;
  }

  .product-description {
    font-size: 0.8rem;
  }

  .video-container iframe {
    height: 250px; /* Reduced height for mobile */
  }
}

/* Additional Styling for Product Links */
.product-link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
}

.product-link:hover .product-card {
  /* Ensure hover effects are maintained when using a link */
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
