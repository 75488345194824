@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'DM Serif Display', serif;
}

/* Brands Carousel Section */
.brands-section {
  background-color: #0c1d3c;
  padding: 60px 20px; /* Increased padding for better spacing */
  color: white;
  text-align: center;
}

.brands-header {
  margin-bottom: 40px;
}

.brands-header h2 {
  font-size: 2.5rem;
  color: #00d8a7;
  position: relative;
  display: inline-block;
}

.brands-header p {
  font-size: 1.2rem;
  color: white;
  margin-top: 10px;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Carousel Wrapper */
.carousel-wrapper {
  height: 180px; /* Increased height for better visibility */
  overflow: hidden;
  margin-bottom: 30px;
}

.brand-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.brand-logo {
  max-height: 160px; /* Adjusted to fit within the increased carousel height */
  object-fit: contain;
  transition: transform 0.3s ease;
}

.brand-logo:hover {
  transform: scale(1.1);
}

/* Know More Button */
.know-more-button {
  background-color: #00d8a7;
  color: #0c1d3c;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.know-more-button:hover {
  background-color: #00b388;
}

.button-icon {
  margin-left: 5px;
}

/* Smooth Hover Underline Effect for Headings */
.brands-header h2::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: #ffff99;
  margin: 10px auto 0;
  transition: width 0.3s;
}

.brands-header h2:hover::after {
  width: 50%;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .brands-section {
    padding: 50px 20px;
  }

  .brands-header h2 {
    font-size: 2.3rem;
  }

  .brands-header p {
    font-size: 1.1rem;
  }

  .carousel-wrapper {
    height: 160px;
  }

  .brand-logo {
    max-height: 140px;
  }
}

@media (max-width: 992px) {
  .brands-section {
    padding: 40px 15px;
  }

  .brands-header h2 {
    font-size: 2.2rem;
  }

  .brands-header p {
    font-size: 1rem;
  }

  .carousel-wrapper {
    height: 150px;
  }

  .brand-logo {
    max-height: 130px;
  }
}

@media (max-width: 768px) {
  .brands-section {
    padding: 30px 15px;
  }

  .brands-header h2 {
    font-size: 2rem;
  }

  .brands-header p {
    font-size: 0.95rem;
  }

  .carousel-wrapper {
    height: 140px;
  }

  .brand-logo {
    max-height: 120px;
  }
}

@media (max-width: 576px) {
  .brands-section {
    padding: 20px 10px;
  }

  .brands-header h2 {
    font-size: 1.8rem;
  }

  .brands-header p {
    font-size: 0.9rem;
  }

  .carousel-wrapper {
    height: 120px;
  }

  .brand-logo {
    max-height: 100px;
  }
}

@media (max-width: 480px) {
  .brands-section {
    padding: 15px 5px;
  }

  .brands-header h2 {
    font-size: 1.6rem;
  }

  .brands-header p {
    font-size: 0.8rem;
  }

  .carousel-wrapper {
    height: 100px;
  }

  .brand-logo {
    max-height: 80px;
  }
}
